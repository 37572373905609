import { useState, useEffect } from 'react';

export const useBodyElement = () => {
  const [root, setRoot] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setRoot(document.querySelector<HTMLElement>('body'));
  }, [root]);

  return {
    root,
  };
};
